import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Navigate} from '@ngxs/router-plugin';
import {AuthState} from '../../auth/states/auth/auth.state';
import {LoginSignupService} from './login-signup.service';
import {SubmissionsService} from '../../shared/microservices/submission/submissions.service';
import {AuthService} from '../../auth/services/auth/auth.service';
import {SubmissionState} from '../../shared/microservices/submission/submission.state';
import {AuthorizationType} from '../../auth/symbols';


@Injectable({
  providedIn: 'root'
})
export class GetStartedService {
  @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;
  @Select(AuthState.hasAccount) hasAccount$: Observable<boolean>;
  @Select(AuthState.isVerified) isVerified$: Observable<boolean>;
  @Select(SubmissionState.hasOwnSubmissions) hasOwnSubmissions$: Observable<boolean>;

  private isAuthenticated = false;
  private hasAccount = false;
  private isVerified = false;
  private hasOwnSubmissions = false;

  constructor(
    private store: Store,
    public ls: LoginSignupService,
    public ss: SubmissionsService,
    public auth: AuthService,
  ) {
    this.isAuthenticated$.subscribe(isAuthenticated => this.isAuthenticated = isAuthenticated);

    this.hasAccount$.subscribe(hasAccount => this.hasAccount = hasAccount);

    this.isVerified$.pipe(filter(x => !!x)).subscribe(isVerified => this.isVerified = isVerified);

    this.hasOwnSubmissions$.pipe(filter(x => !!x)).subscribe(hasOwnSubmissions => this.hasOwnSubmissions = hasOwnSubmissions);
  }

  getStarted(redirect = '/dashboard') {
    if (this.isAuthenticated) {
      this.store.dispatch(new Navigate([redirect]));
    } else {
      // if (this.hasAccount) {
      //   this.ls.login(redirect, redirect);
      // } else {
      //   this.ls.signup(redirect, redirect);
      // }
      this.ls.signup(redirect, redirect);
    }
  }

  getStartedWithCheck(authImmediately = false): void {
    const hasUploaded = this.ss.hasUploaded();

    if (hasUploaded) {
      this.getStarted('/dashboard');
    } else {
      if (authImmediately) {
        this.getStarted('/upload');
      } else {
        this.store.dispatch(new Navigate(['/upload']));

      }
    }
  }

  navigate(redirectUrl = '/dashboard', flowType: AuthorizationType = null, queryParams = null) {
    if (!flowType) {
      this.auth.clearAuthRedirectUrlInStorage();
    }

    if (flowType === AuthorizationType.HAS_MRI) {
      redirectUrl = this.hasOwnSubmissions ? '/dashboard/upload-scan/steps/select' : '/dashboard/upload-scan';
    }

    if (!this.isAuthenticated) {
      // if (this.hasAccount) {
      //   this.ls.login(redirectUrl, redirectUrl, {authType: flowType});
      // } else {
      //   this.ls.signup(redirectUrl, redirectUrl, {authType: flowType});
      // }
      this.ls.signup(redirectUrl, redirectUrl, {authType: flowType});
    } else {
      if (!this.isVerified && flowType) {
        this.auth.saveAuthRedirectUrlToStorage(redirectUrl);
      }
      this.store.dispatch(new Navigate([redirectUrl], queryParams));
    }
  }
}
